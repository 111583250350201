<template>
  <div>
    <slot name="activator" :on="{ click: openDialog }"  />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { OverlayTypes } from '@/store/types/overlay'

export default {
  props: {
    fundingDataIdx: {
      default: null,
    },
  },
  data() {
    return {
      overlayName: '#fd',
    }
  },
  methods: {
    ...mapActions('overlay', {
      passProps: OverlayTypes.actions.PASS_PROPS,
      setOverlay: OverlayTypes.actions.OPEN_OVERLAY,
    }),
    openDialog() {
      if (!!this.fundingDataIdx) {
        this.passProps({ fundingDataIdx: this.fundingDataIdx })
        this.setOverlay(this.overlayName)
      }
    },
  },
}
</script>
